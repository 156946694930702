const USER_ROLES = {
  SUPERADMIN: {
    color: '#ff7070',
    // color: '#3f4c6b',
    title: 'SuperAdmin',
  },
  ADMIN: {
    color: '#ab5f89',
    title: 'Admin',
  },
  MANAGER: {
    color: '#618675',
    title: 'Gestor',
  },
  BUSINES_MANAGER: {
    color: '#618675',
    title: 'Gestor RZ',
  },
  SUPER_MANAGER: {
    color: '#618675',
    title: 'Super Gestor',
  },
  SUBMANAGER: {
    color: '#618675',
    title: 'Gestor',
  },
  ROMANAGER: {
    color: '#5e5e79',
    title: 'Gestor lectura',
  },
  SUPERVISOR: {
    color: '#618675',
    title: 'Supervisor',
  },
  EMPLOYEE: {
    color: '#5e5e79',
    title: 'Trabajador',
  },
  EXTERNAL: {
    color: '#5e5e79',
    title: 'Externo',
  },
};
const CONTRACT_TYPES = {
  INDEFINITE: 'Indefinido',
  FIXED: 'Fijo',
  BY_WORK_OR_TASK: 'Por obra o faena',
  // 'THIRD_PARTY':     'Tercero',
  // 'FREELANCE':       'Freelance',
  // 'SPECIFIC':        'Especifico',
  // 'OTHER':           'Otro',
};
const CONTRACT_TYPES_TO_EN = {
  'por obra o faena': 'BY_WORK_OR_TASK',
  'plazo fijo': 'FIXED',
  indefinido: 'INDEFINITE',
  freelance: 'FREELANCE',
  tercero: 'THIRD_PARTY',
  especifico: 'SPECIFIC',
  otro: 'OTHER',
};
const GENDER_PREVIRED = {
  M: 'Masculino',
  F: 'Femenino',
};
const GENDER_TYPES = {
  M: 'Masculino',
  F: 'Femenino',
  UNKNOWN: 'Desconocido',
  OTHER: 'Otro',
};
// FIXME: esto se puede hacer por codigo en vez de repetir constantes
const GENDER_REVERSE_TYPES = {
  Masculino: 'M',
  Femenino: 'F',
  Desconocido: 'UNKNOWN',
  Otro: 'OTHER',
};

const CONTRACT_STATUS = {
  ACTIVE: 'Activo',
  INACTIVE: 'Inactivo',
  PENDING: 'Pendiente',
};

const CONTRACT_STATUS_NO_PENDING = {
  ACTIVE: 'Activo',
  INACTIVE: 'Inactivo',
};

const CIVIL_STATUS = {
  SINGLE: 'Soltero',
  MARRIED: 'Casado',
  WIDOWER: 'Viudo',
  DIVORCED: 'Divorciado',
  CIVILIAN_PARTNER: 'Unión civil',
  UNKNOWN: 'Desconocido',
};
const IDENTIFICATION_TYPE = {
  RUT: 'RUT',
  NIC: 'NIC (RUT provisorio)',
  DNI: 'DNI (Perú y Argentina)',
  CC: 'CC (Colombia)',
  CI: 'CI (Ecuador y Venezuela)',
  PASSPORT: 'Pasaporte',
  OTHER: 'otro',
};
const SHORT_IDENTIFICATION_TYPE = {
  RUT: 'RUT',
  NIC: 'NIC',
  DNI: 'DNI',
  CC: 'CC',
  CI: 'CI',
  PASSPORT: 'Pasaporte',
  OTHER: 'otro',
};

const PENSION_STATUS = {
  ACTIVE: 'Activo',
  PENSIONED_ACTIVE: 'Pensionado activo',
  PENSIONED_INACTIVE: 'Pensionado inactivo',
  ACTIVE_OVER_65: 'Activo sobre 65',
};
const PREVISION_TYPES = {
  NO_COTIZA: 'No cotiza',
  AFP_FONASA: 'AFP / Fonasa',
  AFP_ISAPRE: 'AFP / Isapre',
  FONASA: 'Fonasa',
  ISAPRE: 'Isapre',
  EX_CAJA: 'Ex Caja',
};
const RETIREMENT_SCHEME_TYPES = {
  AFP: 'AFP',
  IPS: 'IPS',
};

const FAMILY_ALLOWANCE_SEGMENTS = {
  A: 'Tramo A',
  B: 'Tramo B',
  C: 'Tramo C',
  D: 'Tramo D',
  UNKNOWN: 'Sin tramo',
};
const NATIONALITY = {
  0: 'Chilena',
  1: 'Extranjera',
};

const AFP_CODES = {
  capital: '33',
  cuprum: '03',
  habitat: '05',
  planvital: '29',
  provida: '08',
  modelo: '34',
  uno: '35',
  'n/a': '00',
};

const ISAPRE_CODES = {
  fonasa: '07',
  banmedica: '01',
  consalud: '02',
  vidatres: '03',
  colmena: '04',
  cruzblanca: '05',
  nuevamasvida: '10',
  isapredecodelco: '11',
  isaprebcoestado: '12',
  cruzdelnorte: '25',
  'cruz blanca': '05',
  'nueva mas vida': '10',
  'isapre de codelco': '11',
  'isapre bco estado': '12',
  'cruz del norte': '25',
  esencial: '28',
};
const USER_STATUS = {
  ACTIVE: 'Activo',
  SUSPENDED: 'Inactivo',
};

export {
  RETIREMENT_SCHEME_TYPES,
  USER_STATUS,
  USER_ROLES,
  CONTRACT_TYPES,
  CONTRACT_TYPES_TO_EN,
  GENDER_TYPES,
  GENDER_PREVIRED,
  GENDER_REVERSE_TYPES,
  CONTRACT_STATUS,
  CONTRACT_STATUS_NO_PENDING,
  CIVIL_STATUS,
  IDENTIFICATION_TYPE,
  SHORT_IDENTIFICATION_TYPE,
  PENSION_STATUS,
  PREVISION_TYPES,
  FAMILY_ALLOWANCE_SEGMENTS,
  NATIONALITY,
  AFP_CODES,
  ISAPRE_CODES,
};
